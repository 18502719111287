import React from "react";
import Slider from "react-slick";
import styled from "styled-components";
// Components
import TestimonialBox from "../Elements/TestimonialBox";

export default function TestimonialSlider() {
  const settings = {
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    arrows: false,
    responsive: [
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  return (
    <SliderContainer>
      <Slider {...settings}>
        <LogoWrapper className="flexCenter">
          <TestimonialBox
            text="Our safari experience with Jokkajok Safaris surpassed all expectations! From the moment we were greeted by their friendly team, we knew we were in for something special. Every detail of our journey, from the exhilarating game drives to the luxurious accommodations. Jokkajok Safaris truly delivered an unforgettable adventure that we will cherish for a lifetime."
            author="Ted"
          />
        </LogoWrapper>
        <LogoWrapper className="flexCenter">
          <TestimonialBox
            text="Our family had always dreamed of exploring the wonders of Africa, and Jokkajok Safaris helped turn that dream into reality. From the youngest to the oldest member of our group, each moment was filled with wonder and excitement"
            author="Diana K"
          />
        </LogoWrapper>
        <LogoWrapper className="flexCenter">
          <TestimonialBox
            text="I cannot recommend Jokkajok Safaris enough! As someone who values authenticity and sustainability in travel, I was delighted by their commitment to responsible tourism. Every aspect of our trip was meticulously planned, and the level of service provided by their knowledgeable guides and staff was exceptional."
            author="Faith"
          />
        </LogoWrapper>
        <LogoWrapper className="flexCenter">
          <TestimonialBox
            text="Jokkajok Safaris exceeded all of our expectations. The level of service, the comfort of the lodges, and the sheer beauty of the landscapes left us in awe. We left with memories that will last a lifetime and a deep appreciation for Africa's wild wonders."
            author="Kenneth O"
          />
        </LogoWrapper>
       
      </Slider>
    </SliderContainer>
  );
}

const SliderContainer = styled.div`
  width: 100%;
  padding: 20px;
  background-color: #f5f5f5; /* Adjust as needed */
`;

const LogoWrapper = styled.div`
  width: 90%;
  padding: 0 5%;
  cursor: pointer;
  :focus-visible {
    outline: none;
    border: 0px;
  }
`;
