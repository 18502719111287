import React from "react";
import { Helmet } from "react-helmet";
import { Routes, Route } from "react-router-dom";

// Screens
import Landing from "./screens/Landing.jsx";
import About from "./components/Pages/About.jsx";
import ExploreKenya from "./components/Pages/ExploreKenya.jsx";
import ProductPage from "./components/Pages/ProductPage.jsx";
import Dashboard from "./components/Admin/dashboard.js";
import CreateNewSafari from "./components/Admin/createNewSafari.js";


// Layouts
import AdminLayout from "./components/Admin/AdminLayout";
import SafariList from "./components/Admin/SafariList.js";
import EditSafari from "./components/Admin/EditSafari.js";
import CreateNewHotel from "./components/Admin/createNewHotel.js";
import CreateNewHotelAmenity from "./components/Admin/createNewHotelAmenity.js";
import CreateNewHotelType from "./components/Admin/createNewHotelType.js";
import HotelAmenitiesList from "./components/Admin/hotelAmenities.js";
import HotelTypesList from "./components/Admin/hotelTypes.js";
import ViewHotels from "./components/Admin/HotelList.js";
import EditHotel from "./components/Admin/EditHotel.js";
import AddBooking from "./components/Admin/createNewBooking.js";
import BookingList from "./components/Admin/BookingsList.js";
import EditBooking from "./components/Admin/EditBooking.js";
import Hotels from "./components/Pages/Hotels.jsx";
import BookingForm from "./components/Pages/Booking.jsx";
import AddBlog from "./components/Admin/createNewBlog.js";
import BlogList from "./components/Admin/BlogList.js";
import EditBlogPage from "./components/Admin/EditBlog.js";
import AllBlogs from "./components/Pages/AllBlogs.jsx";
import BlogDetails from "./components/Pages/BlogDetails.jsx";
import SafariPage from "./components/Pages/SafariDetails.jsx";
import CreateUser from "./components/Admin/CreateUser.js";
import UserList from "./components/Admin/UsersList.js";
import LoginForm from "./components/Admin/LoginForm.js";
import RegisterForm from "./components/Admin/CreateUser.js";
import ProtectedRoute from "./components/Admin/ProtectedRoute.js";

export default function App() {
  return (
    <>
      <Helmet>
        <link rel="preconnect" href="https://fonts.googleapis.com" />
        <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin />
        <link href="https://fonts.googleapis.com/css2?family=Khula:wght@400;600;800&display=swap" rel="stylesheet" />
      </Helmet>
      <Routes>
        <Route path="/" element={<Landing />} />
        <Route path="/about" element={<About />} />
        <Route path="/explore-kenya" element={<ExploreKenya />} />
        <Route path="/product-page/:id" element={<ProductPage />} />
        <Route path="/hotels" element={<Hotels />} />
        <Route path="/booking-form" element={<BookingForm />} />
        <Route path="/all-blogs" element={<AllBlogs />} />
        <Route path="/blog/:blogId" element={<BlogDetails />} />
        <Route path="/safari/:safariID" element={<SafariPage />} 




        /> 

        {/* Admin Routes */}

        <Route path="/login" element={<LoginForm />} />
        <Route path="/register" element={<RegisterForm />} />



        <Route 
          path="/dashboard" 
          element={
            <>
            <ProtectedRoute>
              <AdminLayout>
              <Dashboard />
              </AdminLayout>
            </ProtectedRoute>
            </>
          } 
        />
        <Route 
          path="/createNewSafari" 
          element={
            <AdminLayout>
              <CreateNewSafari />
            </AdminLayout>
          } 
        />
        <Route 
          path="/safari-list" 
          element={
            <AdminLayout>
              <SafariList />
            </AdminLayout>
          } 
        /> 
        <Route 
          path="/edit-safari/:safariID" 
          element={
            <AdminLayout>
              <EditSafari />
            </AdminLayout>
          } 
        /> 

        <Route 
          path="/createNewHotel" 
          element={
            <AdminLayout>
              <CreateNewHotel />
            </AdminLayout>
          } 
        /> 

        <Route 
          path="/createNewHotelAmenity" 
          element={
            <AdminLayout>
              <CreateNewHotelAmenity />
            </AdminLayout>
          } 
        /> 

        <Route 
          path="/createNewHotelType" 
          element={
            <AdminLayout>
              <CreateNewHotelType />
            </AdminLayout>
          } 
        /> 

        <Route 
          path="/hotelAmenitiesList" 
          element={
            <AdminLayout>
              <HotelAmenitiesList />
            </AdminLayout>
          } 
        /> 

        <Route 
          path="/hotelTypeList" 
          element={
            <AdminLayout>
              <HotelTypesList />
            </AdminLayout>
          } 
        /> 

        <Route 
          path="/hotelList" 
          element={
            <AdminLayout>
              <ViewHotels />
            </AdminLayout>
          } 
        /> 

        <Route 
          path="/editHotel/:id" 
          element={
            <AdminLayout>
              <EditHotel />
            </AdminLayout>
          } 
        /> 

        <Route 
          path="/createNewBooking" 
          element={
            <AdminLayout>
              <AddBooking />
            </AdminLayout>
          } 
        /> 

        <Route 
          path="/bookingList" 
          element={
            <AdminLayout>
              <BookingList />
            </AdminLayout>
          } 
        /> 

        <Route 
          path="/editBooking/:bookingID" 
          element={
            <AdminLayout>
              <EditBooking />
            </AdminLayout>
          } 
        /> 

        <Route 
          path="/createNewBlog" 
          element={
            <AdminLayout>
              <AddBlog />
            </AdminLayout>
          } 
        /> 

        <Route 
          path="/blogList" 
          element={
            <AdminLayout>
              <BlogList />
            </AdminLayout>
          } 
        /> 

        <Route 
          path="/editBlog/:blogId" 
          element={
            <AdminLayout>
              <EditBlogPage />
            </AdminLayout>
          } 
        /> 

        <Route 
          path="/createUser" 
          element={
            <AdminLayout>
              <CreateUser />
            </AdminLayout>
          } 
        /> 
        <Route 
          path="/userList" 
          element={
            <AdminLayout>
              <UserList />
            </AdminLayout>
          } 
        /> 


      </Routes>
    </>
  );
}
