import React, { useState } from 'react';
import axios from 'axios';
import {
  TextField,
  Button,
  Container,
  Typography,
  Box
} from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import dayjs from 'dayjs';

const AddBooking = () => {
  const [booking, setBooking] = useState({
    packageType: '',
    startDate: null,
    endDate: null,
    noOfAdults: '',
    noOfChildren: '',
    fname: '',
    lname: '',
    phoneNumber: '',
    email: '',
    message: '',
    countryOfResidence: '',
  });

  const apiUrl = process.env.REACT_APP_API_BASE_URL;

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setBooking({ ...booking, [name]: value });
  };

  const handleDateChange = (name, value) => {
    setBooking({ ...booking, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const newBooking = {
      ...booking,
      startDate: booking.startDate.toISOString(),
      endDate: booking.endDate.toISOString(),
      createdAt: new Date().toISOString(),
    };

    try {
      await axios.post(`${apiUrl}/api/v1/booking`, newBooking);
      alert('Booking created successfully!');
      // Reset form after submission
      setBooking({
        packageType: '',
        startDate: null,
        endDate: null,
        noOfAdults: '',
        noOfChildren: '',
        fname: '',
        lname: '',
        phoneNumber: '',
        email: '',
        message: '',
        countryOfResidence: ''
      });
    } catch (error) {
      console.error('Error creating booking:', error);
    }
  };

  return (
    <Container style={{ marginTop: '20px' }}>
      <Typography variant="h4" gutterBottom>
        Add New Booking
      </Typography>
      <form onSubmit={handleSubmit}>
        <Box mb={2}>
          <TextField
            label="Package Type"
            name="packageType"
            variant="outlined"
            fullWidth
            value={booking.packageType}
            onChange={handleInputChange}
          />
        </Box>
        <Box mb={2}>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker
              label="Start Date"
              value={booking.startDate}
              onChange={(value) => handleDateChange('startDate', value)}
              renderInput={(params) => <TextField {...params} fullWidth />}
            />
          </LocalizationProvider>
        </Box>
        <Box mb={2}>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker
              label="End Date"
              value={booking.endDate}
              onChange={(value) => handleDateChange('endDate', value)}
              renderInput={(params) => <TextField {...params} fullWidth />}
            />
          </LocalizationProvider>
        </Box>
        <Box mb={2}>
          <TextField
            label="Number of Adults"
            name="noOfAdults"
            variant="outlined"
            fullWidth
            type="number"
            value={booking.noOfAdults}
            onChange={handleInputChange}
          />
        </Box>
        <Box mb={2}>
          <TextField
            label="Number of Children"
            name="noOfChildren"
            variant="outlined"
            fullWidth
            type="number"
            value={booking.noOfChildren}
            onChange={handleInputChange}
          />
        </Box>
        <Box mb={2}>
          <TextField
            label="First Name"
            name="fname"
            variant="outlined"
            fullWidth
            value={booking.fname}
            onChange={handleInputChange}
          />
        </Box>
        <Box mb={2}>
          <TextField
            label="Last Name"
            name="lname"
            variant="outlined"
            fullWidth
            value={booking.lname}
            onChange={handleInputChange}
          />
        </Box>
        <Box mb={2}>
          <TextField
            label="Country of Residence"
            name="countryOFResidence"
            variant="outlined"
            fullWidth
            value={booking.countryOfResidence}
            onChange={handleInputChange}
          />
        </Box>
        <Box mb={2}>
          <TextField
            label="Phone Number"
            name="phoneNumber"
            variant="outlined"
            fullWidth
            value={booking.phoneNumber}
            onChange={handleInputChange}
          />
        </Box>
        <Box mb={2}>
          <TextField
            label="Email"
            name="email"
            variant="outlined"
            fullWidth
            type="email"
            value={booking.email}
            onChange={handleInputChange}
          />
        </Box>
        <Box mb={2}>
          <TextField
            label="Message"
            name="message"
            variant="outlined"
            fullWidth
            value={booking.message}
            onChange={handleInputChange}
          />
        </Box>
        <Button type="submit" variant="contained" color="primary">
          Add Booking
        </Button>
      </form>
    </Container>
  );
};

export default AddBooking;
